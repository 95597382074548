@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&display=swap');

.Inclusive-addStory-page {
    max-width: 90vw;
    margin: 1.2rem auto;
}

@media only screen and (min-width: 1000px) {
    .Inclusive-addStory-page {
        max-width: 1000px;
    }
}

.Inclusive-addStory-page .addStory-form {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addStory-form input#title {
    width: 100%;
    height: 55px;
    outline: 0;
    border: 1px solid rgb(235, 235, 235);
    font-size: 1.2rem;
    padding-left: 1rem;
    margin-bottom: 10px;
}

/* Wrapper for CKEditor */
.editor-wrapper {
    width: 100%;
    margin-bottom: 20px;
}

.editor-wrapper label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    font-size: large;
    color: #0063a5;
}

/* CKEditor Styles */
.ck-editor__editable {
    width: 100%;
    min-height: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff;
}

.ck-editor {
    margin-bottom: 1rem !important;
}

@media only screen and (max-width: 650px) {
    .Inclusive-addStory-page .addStory-form {
        width: 100%;
        padding: 0 1rem;
    }
}

.ck-editor__top {
    border: 1px solid rgb(235, 235, 235);
    padding: 0.6rem 1rem;
}

.ck-content {
    border: 1px solid rgb(235, 235, 235);
}

.StoryImageField {
    width: 100%;
    height: auto;
    min-height: 240px;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-top: 1rem;
    position: relative;
}

.StoryImageField .txt {
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 14px;
}

.StoryImageField [type="file"] {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.addStory-btn, .dis-btn {
    width: 100%;
    background-color: #0063a5;
    padding: 0.7rem;
    color: white;
    margin-top: 1.9rem;
    border: none;
    border-radius: 6px;
    font-size: 1.1rem;
}

.addStory-form .error_msg, .addStory-form .success_msg {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    color: white;
    padding: 8px;
    font-size: 0.9rem;
    text-align: center;
}

.addStory-form .error_msg {
    background-color: rgba(245, 72, 72, 0.9);
}

.addStory-form .success_msg {
    background-color: rgba(14, 218, 75, 0.9);
}

.addStory-form .success_msg a {
    color: black;
    font-weight: bold;
    padding-left: 1rem;
}
