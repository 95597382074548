.testimonial-form-container {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-details {
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .project-name, .project-author {
    font-size: 1.1rem;
    color: #333;
    margin: 5px 0;
  }
  
  .project-name strong, .project-author strong {
    color: #2c3e50;
    font-weight: bold;
  }
  
  .project-details p {
    margin: 0;
    padding: 0;
  }

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    font-weight: bold;
}

input[type="text"],
textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
}

textarea {
    resize: vertical;
}

.error-message {
    color: red;
    font-size: 0.875rem;
}

.success-message {
    color: green;
    font-size: 0.875rem;
}

.form-actions {
    display: flex;
    justify-content: center;
}

.submit-btn {
    padding: 10px 20px;
    background-color: #04599C;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #003f6e;
}

/* Media Queries */
@media (max-width: 768px) {
    .testimonial-form-container {
        padding: 15px; /* Reduce padding on smaller screens */
    }

    h2 {
        font-size: 1.5rem; /* Adjust heading size */
    }

    input[type="text"],
    textarea {
        font-size: 0.9rem; /* Slightly smaller font size */
    }

    .submit-btn {
        padding: 8px 16px; /* Adjust button size */
    }
}

@media (max-width: 480px) {
    .testimonial-form-container {
        padding: 10px; /* Further reduce padding on very small screens */
    }

    h2 {
        font-size: 1.25rem; /* Further adjust heading size */
    }

    input[type="text"],
    textarea {
        font-size: 0.85rem; /* Further reduce font size */
    }

    .submit-btn {
        padding: 6px 12px; /* Further adjust button size */
    }
}
