/* ReceiveTestimonialPage.css */

/* Page container */
.receive-testimonial-page {
    max-width: 800px; /* Limiting width for better readability */
    margin: 50px auto; /* Centering the content with top margin */
    padding: 40px; /* Adding some padding */
    background-color: #f9f9f9; /* Light background color for contrast */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    min-height: calc(100vh - 100px); /* Ensuring enough height for centering */
    display: flex; /* Using flexbox for centering */
    flex-direction: column; /* Align items in a column */
    justify-content: center; /* Centering vertically */
  }
  
  /* Heading */
  h1 {
    text-align: center; /* Centering the title */
    color: #333; /* Dark color for contrast */
    font-size: 2rem; /* Larger font size for the heading */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  /* Optional: Global styles for consistency */
  body {
    font-family: 'Inter', sans-serif; /* Setting a consistent font */
    background-color: #e9ecef; /* Soft background color */
    margin: 0; /* Removing default margin */
    padding: 0; /* Removing default padding */
  }
  
  h1, h2 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  