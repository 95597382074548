.Inclusive-resetPassword-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDF0F7;
}

.Inclusive-resetPassword-page h3 {
    margin-bottom: 2.1rem;
    font-weight: bolder;
    text-align: center;
    font-size: 2.2rem;
    letter-spacing: 0.8px;
    color: #0063a5;
}

.Inclusive-resetPassword-page form {
    padding: 4.2rem 3.2rem;
    height: 500px;
    background-color: white;
    border-radius: 11px;
    width: 520px;
}

.Inclusive-resetPassword-page form .input-wrapper {
    position: relative;
    margin-bottom: 18px;
    padding: 6px;
}

.Inclusive-resetPassword-page form .input-wrapper input {
    outline: 0;
    width: 100%;
    border: #d1d1d1 solid 1px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #252525;
    padding: 24px 0 16px 16px;
}

.Inclusive-resetPassword-page form .input-wrapper label {
    position: absolute;
    top: -5px;
    left: 14px;
    font-size: 0.8rem;
    background-color: white;
    color: #646464;
    padding: 0 8px;
}

.Inclusive-resetPassword-page form input:focus-within {
    border: #0205b1 solid 1px;
}

.Inclusive-resetPassword-page form input:focus+label {
    color: #0205b1 !important;
}

.resetPass-btn {
    width: 100%;
    background-color: #0063a5;
    padding: 0.7rem;
    color: white;
    margin-top: 1.2rem;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
}

.Inclusive-resetPassword-page form .error_msg {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    background-color: rgba(245, 72, 72, 0.9);
    color: white;
    padding: 8px;
    font-size: 0.9rem;
    text-align: center;
}

.Inclusive-resetPassword-page form .success_msg {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    background-color: rgba(14, 218, 75, 0.9);
    color: white;
    padding: 8px;
    font-size: 0.9rem;
    text-align: center;
}

.Inclusive-resetPassword-page form .success_msg a {
    color: black;
    font-weight: bold;
    padding-left: 1rem;
}