/* Modal wrapper */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay background with more opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    backdrop-filter: blur(5px); /* Add a blur effect to the background */
}

/* Modal content */
.modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px; /* Slightly more rounded corners */
    width: 500px;
    max-width: 90%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
    animation: fadeIn 0.3s ease-in-out;
    transition: transform 0.3s ease; /* Smooth transform transition */
}

/* Header */
h2 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
    text-align: center;
    font-weight: bold;
}

/* Form group */
.form-group {
    margin-bottom: 20px; /* Increased spacing for a cleaner look */
}

label {
    display: block;
    font-weight: 600; /* Slightly bolder */
    margin-bottom: 8px;
    color: #555; /* Darker gray for better contrast */
}

input[type="text"],
textarea {
    width: 100%;
    padding: 12px; /* Increased padding for better touch targets */
    border-radius: 8px; /* More rounded corners */
    border: 1px solid #ccc;
    font-size: 1rem;
    box-sizing: border-box;
    transition: border 0.3s ease; /* Smooth border transition */
}

input[type="text"]:focus,
textarea:focus {
    border-color: #007bff; /* Border color on focus */
    outline: none; /* Remove default outline */
}

/* Style for textarea */
textarea {
    resize: vertical;
    min-height: 100px; /* Increased min height for more space */
}

/* Error message */
.error-message {
    color: #d9534f; /* Bootstrap danger color */
    font-size: 0.875rem;
    margin-top: 5px;
}

/* Form actions */
.form-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.submit-btn,
.cancel-btn {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 8px; /* More rounded buttons */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s; /* Smooth transitions */
}

.submit-btn {
    background-color: #04599C;
    color: white;
}

.submit-btn:hover {
    background-color: #04538f;
    transform: translateY(-2px); /* Lift effect on hover */
}

.cancel-btn {
    background-color: #f0f0f0;
    color: #333;
}

.cancel-btn:hover {
    background-color: #d9d9d9; /* Darker on hover */
    transform: translateY(-2px); /* Lift effect on hover */
}

/* Modal fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
